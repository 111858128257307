<template>
  <NewsCollection />
</template>

<script>
import NewsCollection from "@/components/common/NewsCollection";

export default {
  name: "News",
  components:{
    NewsCollection,
  }
}
</script>

<style scoped>

</style>